///////////// CUSTOMIZED - Burst Style
section.section-header.index {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -55px;
    position: absolute;
    width: 100%;
    z-index: 9;
    padding: 0;
    @media screen and (max-width: $size-max-m-2) {
        margin-top: -47px;
        transition: .5s;
    }

    div.section-icon-bursts {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;

        div.row {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0;

            div.icon-bursts {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                div.row {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    @media screen and (max-width: $size-max-m-2) {
                        //justify-content: center;
                        flex-wrap: wrap;
                        transition: .5s;
                    }

                    div.icon-burst {
                        width: 22%;
                        max-width: 365px;
                        min-width: 250px;
                        height: 128px;
                        display: flex;
                        background: url(../images/section_03_tab.png) no-repeat center center;
                        background-size: 100% 100%;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            height: 110px;
                            min-width: 200px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                width: 100%;
                                max-width: 100%;
                                background: url(../images/section_03_tab_full.png) no-repeat center center;
                                background-size: 850px 100%;
                                transition: .5s;
                            }
                        }

                        a {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                            margin-top: -15px;
                            width: 100%;
                        }

                        div.text-bloc {
                            margin-top: 5px;
                            h3 {
                              text-align: center;
                                text-transform: none;
                                color: $color-white;
                                font-family: $font-family-3;
                                font-size: 22px;
                                text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 18px;
                                    transition: .5s;
                                }
                            }
                            p { display: none; }
                        }

                        div.content-img {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img {
                                width: 70px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 60px;
                                    transition: .5s;
                                }
                            }
                            img:nth-child(1) {
                                opacity: 1;
                            }
                            img:nth-child(2) {
                                position: absolute;
                                opacity: 0;
                            }
                        }

                        ///////// LEFT-SIDE
                        div.burst-left {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                        }

                        ///////// MIDDLE-SIDE
                        div.burst-middle {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                        }

                        ///////// RIGHT-SIDE
                        div.burst-right {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                        }

                        div.burst-left:hover,
                        div.burst-middle:hover,
                        div.burst-right:hover {
                            img:nth-child(1) {
                                opacity: 0;
                                transition: .5s;
                            }
                            img:nth-child(2) {
                                opacity: 1;
                                position: absolute;
                                transition: .5s;
                            }
                            h3 {
                                //text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
                                color: $color-maron-hover-2;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}
.bursts {
    width: 100%;
    .bursts-container {
        @extend .flex;
        .burst {
            // flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 25px;
                .border {
                    border: 1px solid $color-white;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                }
            }
            .overlay {
                background-image: linear-gradient(to top, $color-secondary,$color-primary);
                // z-index: 1;
                mix-blend-mode: hue;
                opacity: 0;
                transition: all 0.2s ease;
                // for IE explorer fallback
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background: transparentize($color-black, 0.5);
                }
                // For Edge fallback
                @supports (-ms-ime-align:auto) {
                    background: transparentize($color-black, 0.5);
                }
            }
            h3,h4 {
                color: $color-white;
            }
            h3 {
                @extend .font-2;
                font-size: 4vw;
                font-weight: 600;
                text-transform: none;
            }
            h4 {
                @extend .font-1;
                font-size: 2vw;
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 0.5;
            }
            h3, h4 {
                color: $color-secondary;
            }
            .border {
                border-color: $color-secondary;
            }
        }
    }
}
