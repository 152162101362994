
.hidden-mobile {
    display: block;
}
.hidden-desktop {
    display: none;
}

@media screen and (max-width: $size-sm-max) {
    .hidden-mobile {
        display: none !important;
    }
    .hidden-desktop {
        display: block;
    }
}