html {
    font-size: $font-size-base-lg; // used for rem units
}
@media screen and (max-width: $size-md-max) {
    html {
        font-size: $font-size-base-md;
    }
}
@media screen and (max-width: $size-sm-max) {
    html {
        font-size: $font-size-base-sm;
    }
}
@media screen and (max-width: $size-xs-max) {
    html {
        font-size: $font-size-base-xs;
    }
}
// @media screen and (min-width: 1400px) {
//     .container {
//       width: 1400px
//     }
// }
body {
    @extend .text-color;
    @extend .font-1;
    line-height: 1;
    a {
        //@extend .text-color-primary;
        color: $color-green-2;
        @extend .font-1;
        text-decoration: none !important;
        &:hover, &:focus {
            text-decoration: none !important;
             //@extend .text-color-contrast;
            color: $color-black;
        }
    }
    a[href^=tel], a[href^=mailto] {
        &:hover {

        }
    }
}

h1, h2, h3, h4, h5, h6 {
    @extend .font-1;
}

h3 {
  @extend .font-3;
  @media screen and (max-width: $size-xs-max) {
    text-align: center;
  }
}

h4 {
  @extend .font-1-bold;
  color: $color-black;
  @media screen and (max-width: $size-xs-max) {
    text-align: center;
  }
}

p {
    @extend .font-1-medium;
    line-height: 1.7;
    font-size: $font-size-20;
    @media screen and (max-width: $size-xs-max) {
      text-align: center;
    }
}
ul {padding-left: 20px;}
li {
  @extend .font-1-medium;
  line-height: 3;
  font-size: $font-size-20;
}

.text p, .text div {
    @extend .text-color;
    line-height: 1.4;
}
.text p, .text div {
    @extend .font-1;
}
.text strong {
    color: $color-primary;
    font-weight: 900;
}
.text em {
    color: $color-2;
    font-style: normal;
    font-weight: 600;
}
