.btn {
    background-color: $color-primary;
    color: $color-white;
    border: 3px solid $color-white;
    border-radius: 0px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    span.btn-em {
        font-size: 20px;
        text-transform: uppercase;
        font-weigth: 600;
    }
    &:hover {
        color: $color-secondary;
    }
}

.btn-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  & > div {
    margin: 25px 5px;
  }
  div.btn-general {
    background: $color-green-2;
    height: 55px;
    width: calc(50% - 10px);
  }
  div.btn-general:hover {
      background: $color-maron-hover;
      transition: .5s;
  }
  @media screen and (max-width: $size-xs-max){

      flex-direction: column;
      width: 100%;
      div.btn-general {
        width: 100%;
        max-width: 280px;
      }
  }
}
