/////////// POPUP - Style

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
  //padding-top: 12vw;
  transition: .5s;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  //margin: 15% auto; /* 15% from the top and centered */
  padding: 0;
  border: 0 solid #888;
  max-width: 750px; /* Could be more or less, depending on screen size */
  width: 95%;
  transition: .5s;

  div.header-modal {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #642925;
    transition: .5s;

    h3.title {
      text-transform: none;
      color: $color-white;
      font-size: 22px;
      font-weight: 500;
    }
  }

  div.body-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 325px;
    padding: 15px;
    padding-bottom: 45px;
    
    div.header-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      padding-top: 5px;
      width: 100%;

      h3.title-inner {
        font-size: 22px;
        font-weight: 500;
        line-height: 25px;
        text-align: left;
        color: $color-black;
        text-transform: none;
      }

      label.date {
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        text-align: left;
        color: $color-black;
        margin-right: 15px;
      }
    }
    
    p {
      color: $color-black;
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      font-family: $font-family-1;
    }
  }
}

div.date-important {
  div.header-inner-popup {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    h3.inner-titler {
      font-size: 22px;
      color: $color-black;
      font-weight: 400;
    }
    h3.date-important {
      font-size: 18px;
      color: $color-green-2;
      font-weight: 400;
      margin-top: 5px;
      text-transform: none;
    }
  }
}

div.block-unique {
  width: 100%;
  border-bottom: 1px solid $color-green-2;
  margin-bottom: 45px;
}

/* The Close Button */
.close {
    color: #fcf8e3;
    float: right;
    font-size: 42px;
    font-weight: 400;
    opacity: 1;
    transition: .5s;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: .5s;
}
