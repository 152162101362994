.slides {
    margin-top: -$size-nav-height;
    @media screen and (max-width: $size-nav-shift-breakpoint) {
        margin-top: -$size-nav-height-mobile;
    }
}
.slideshow-wrap {
    position: relative;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-size: 1.5vw;
        text-align: center;
        padding-bottom:0.5em;
    }
    .title {
        @extend .font-2;
        text-transform: uppercase;
        color: $color-primary;
        font-size: 3vw;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }
    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .title {
            font-size: 26px;
        }
        .subtitle {
            font-size: 16px;
        }

    }
}


/////////////////// CAPTION SECTION STYLE
div.caption.container {    
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    div.row {
        display: flex;
        justify-content: center;
        align-items: center;

        div.col-xs-12 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            div.content-logo {
                width: 150px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    width: 120px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        width: 90px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 70px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                width: 50px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    width: 80px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }

            h3.title-header {
                line-height: 55px;
                text-align: center;
                text-transform: none;
                color: $color-white;
                font-size: 48px;
                font-family: $font-family-3;
                font-weight: 600;
                text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 40px;
                    line-height: 50px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 35px;
                        line-height: 40px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 30px;
                            line-height: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 2.2rem;
                                line-height: 2.2rem;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    line-height: 2.6rem;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        font-size: 22px;
                                        line-height: 20px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-3) {
                                            font-size: 25px;
                                            line-height: 30px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
