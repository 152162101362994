
/////////////////////////////////////////////////////////////
///MIXIN POUR LA SECCION DE PERSONNEL DIRECTIF
///CHANGE LA CULEUR DU TEXTE ET LE MARGIN ENTRE ITEMS

@mixin section-personnel-directif($text-color, $item-margin) {
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
    .title-box {
      text-align: center;
      margin-bottom: 80px;
      border-bottom: 2px solid $text-color;
      h3 {
        padding-bottom: 30px;
        color: $text-color;
      }
    }
    .persons-liste {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .item {
        flex: 0 1 35%;
        margin: $item-margin 0;
        & > * {
          line-height: 1.7;
          color: $text-color;
          @extend .font-1-medium;
        }

      }
      h3.name {
        font-size: $font-size-30;
        color: $text-color;
      }
      h4.poste {
        text-transform: uppercase;
        font-size: $font-size-18;
        border-bottom: 2px solid $text-color;
      }
    }
    @media screen and (max-width: $size-xs-max) {
      .row {width: 100%;}
    }
    @media screen and (max-width: $size-max-m-3) {
      .persons-liste {
        justify-content: center;
        .item {
          margin: 30px 0;
          flex: 0 300px;
          text-align: center;
        }
      }
    }
}



@mixin section-listes {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    & > div {
      flex: 0 1 50%;
      &.flex-1 {
        flex: 0 1 90%;
      }
    }
    h4 {
      @extend .font-3-bold;
      font-size: $font-size-30;
    }
    @media screen and (max-width: $size-xs-max){
        justify-content: center;
        & > div {
          flex: 0 1 100%;
        }
        ul {
          padding: 0;
          list-style: none;
          text-align: center;
        }
    }

}
