/* Animations */
/* Pour utiliser:
animation: appear;
animatio-delay: 1s;
*/

@keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes drop-down {
    from {top: -100vh;}
    to {top: 0px;}
}
@keyframes come-up {
    from {bottom: -100vh;}
    to {bottom: 0px;}
}
@keyframes zoom-in {
    from {transform: translate(-100vw);}
    to {transform: translate(0);}
}