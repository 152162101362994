.module-header {
  position: relative;
  margin-top: -125px;
  .caption {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
      h3 {
        width: 100%;
        text-align: center;
        font-family: $font-family-3;
        font-size: $font-size-60;
        color: #fff;
        text-shadow: 0 0 10px #000;
        font-weight: 700;
        @include transition(opacity .75s ease-in);
      }
  }
  @media screen and (max-width: $size-xs-max) {
    .caption {
      h3 {
        font-size: $font-size-40;
      }
    }
  }
}
