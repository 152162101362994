// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';

/* purgecss start ignore */

// @import 'addons/font-awesome/font-awesome';
// @import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
// @import 'addons/magnific-popup/magnific-popup';
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';
@import '_alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'reset';
@import 'variables';
@import 'functions';
@import 'mixins';

/* GENERAL TEMPLATE */

body {
    background-color: $bg-color-body;

    h1.referencement {
        display: none !important;
    }
    .appear {
        opacity: 0;
        @include transition(opacity 0.75s ease-in);
    }
    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

////////////// SECTION 03 - PARALAX
section.section-03-paralaxe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: url(../images/bg_parallax_01.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    @media screen and (max-width: $size-max-m-3) {
        //margin-top: 450px;
    }

    div.container-fluid.row {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        @media screen and (max-width: $size-max-m-4) {
            padding: 0;
            transition: .5s;
        }

        div.center-block-text {
            width: 50%;
            display: flex;
            background: $color-maron-2;
            justify-content: center;
            align-items: center;
            padding: 125px 45px;
            padding-bottom: 45px;
            min-width: 650px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                min-width: 100%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    margin-top: 285px;
                    padding: 125px 25px;
                    padding-bottom: 25px;
                    transition: .5s;
                }
            }
            div.separator-border {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                border: 5px solid $color-white;
                margin-top: -100px;
                padding: 45px;
                border-top: 0;
                padding-bottom: 0;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    padding: 15px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        margin-top: -127px;
                        transition: .5s;
                    }
                }

                p {
                    color: $color-black;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: center;
                    line-height: 25px;
                    margin-bottom: 25px;
                    transition: .5s;
                }
                p:nth-child(1) {
                    margin-top: 100px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        margin-top: 50px;
                        transition: .5s;
                    }
                }

                div.content-img {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 25px;
                    transition: .5s;
                    img {
                        width: 150px;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

////////////////// SECTION 04 - PARALAX
section.section-04-paralaxe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: url(../images/bg_parallax_02.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;

    div.container-fluid.row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 65px 0;
        padding-bottom: 0;
        width: 100%;
        @media screen and (max-width: $size-max-m-4) {
            padding: 0;
            transition: .5s;
        }

        div.center-block-text {
            width: 50%;
            min-height: 722px;
            display: flex;
            background: url(../images/section_02_img.png) no-repeat center center;
            background-size: 100% 100%;
            background-position-x: -1vw;
            justify-content: center;
            align-items: center;
            padding: 125px 9vw;
            padding-bottom: 45px;
            min-width: 750px;
            max-width: 850px;
            @media screen and (max-width: $size-max-m-2) {
                background-position-x: -2vw;
                min-width: 100%;
                max-width: 100%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    background-size: cover;
                    background-position-x: -160px;
                    transition: .5s;
                }
            }
            div.separator-border {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin-top: -62px;
                margin-right: -70px;
                padding: 0;
                border-top: 0;
                padding-bottom: 0;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    margin-top: 50px;
                    margin-right: 0;
                    transition: .5s;
                }

                p {
                    color: $color-black;
                    font-weight: 400;
                    font-size: 17px;
                    font-family: $font-family-3;
                    text-align: center;
                    line-height: 30px;
                    margin-bottom: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        padding-left: 45px;
                        padding-right: 45px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            padding-right: 0;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                padding-left: 0;
                                transition: .5s;
                            }
                        }
                    }
                }
                p:nth-child(1) {
                    margin-top: 100px;
                }

                div.content-img {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 25px;
                    img {
                        width: 150px;
                    }
                }
            }
        }
    }
}

////////// SECTION  05 - Lines
section.section-05-lines {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 65px 0;
    padding-bottom: 0;

    div.container-fluid.row {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        @media screen and (max-width: $size-max-m-2) {
            padding: 0;
            transition: .5s;
        }

        div.line-horizontal {
            //width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url(../images/section_03_tab_full.png) no-repeat center center;
            background-size: 100% 100%;
            padding: 40px 0;
            margin: 0 0;
            @media screen and (max-width: $size-max-3) {
                width: 100%;
                @media screen and (max-width: $size-max-m-2) {
                    background-size: 150% 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        background-size: 190% 100%;
                        transition: .5s;
                    }
                }
            }

            a {
                width: 100%;
                font-size: 32px;
                font-weight: 600;
                line-height: 32px;
                margin-top: -18px;
                text-align: center;
                color: $color-white;
                text-transform: none;
                font-family: $font-family-3;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 27px;
                    line-height: 30px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 25px;
                        line-height: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 20px;
                            line-height: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                font-size: 16px;
                                line-height: 20px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }

    div.message-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 4vw;
        width: 75%;
        @media screen and (max-width: $size-max-m-3) {
            width: 100%;
            padding: 10px;
            transition: .5s;
        }

        .date {
            color: $color-black;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
        }
        p {
            color: $color-black;
            font-weight: 400;
            font-size: 18px;
            text-align: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 16px;
                transition: .5s;
            }
        }
    }
}

//////////////// SECTION 06 - dates
section.section-06-dates {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 65px;
    margin-top: 15px;
    @media screen and (max-width: $size-max-m-2) {
        margin-top: 25px;
        transition: .5s;
    }

    div.container-fluid.row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;

        div.box-date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                margin-bottom: 45px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    width: 92%;
                    margin-bottom: 45px;
                    transition: .5s;
                }
            }

            div.header-block {
                width: 100%;
                height: 50px;
                display: flex;
                background: $color-green-2;
                text-align: center;
                justify-content: center;
                align-items: center;
                h3 {
                    color: $color-white;
                    text-transform: none;
                    font-family: $font-family-3;
                    text-align: center;
                    font-size: 22px;
                }
            }

            div.body-block {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 15px;

                h3.title-i {
                    color: $color-black;
                    text-align: center;
                    font-weight: 500;
                    font-size: 20px;
                }

                p {
                    text-align: center;
                    color: $color-black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25px;
                    margin-top: 25px;
                    margin-bottom: 25px;
                    @media screen and (max-width: $size-max-m-3) {
                        margin-top: 10px;
                        margin-bottom: 15px;
                        transition: .5s;
                    }
                }

            }
        }
    }
}
/////////////////////////////////////////////////////////////////////////////
////    REMPLISSAGE    //////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

//section-white-style
section.white-section-style {
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-box {
    h3 {color: $color-green-2;}

    p {color: $color-black;}

    img {margin: 50px auto 50px 0;}

    & > * {
      padding-bottom: 40px;
      &:nth-last-child(1){
        padding-bottom: 60px;
      }
    }
    .directeur {
      h3 {
        font-size: $font-size-30;
        font-weight: 400;
        line-height: 2;
        color: $color-black;
      }
      h4 {
        color: $color-green-2;
      }
    }
  }
  .portrait {
    align-self: flex-end;
  }
  @media screen and (max-width: $size-xs-max){
    .row {flex-direction: column;}
    .content-box {
      img {margin: 50px auto;}
    }
  }
  @media screen and (max-width: $size-max-m-3){
    .content-box {
      text-align: center;
      & > * {
        padding-bottom: 20px;
        &:nth-last-child(1){
          padding-bottom: 30px;
        }
      }
    }
  }
}




/////////////.parallax-green
.parallax-green {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: url(../images/bg_parallax_02.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    padding: 70px 0;
}

//mixin dans scss/_mixins.scss
.section-personnel-white-text {
  @include section-personnel-directif($color-white, 50px);
}


.section-personnel-green-text {
  @include section-personnel-directif($color-green-2, 50px);
}
.personnel-ensegnant {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-green-2;
  @include section-personnel-directif($color-white, 15px);
}

//section section-projet-educatif

.section-02-projet-educatif {

  @extend .parallax-green;
  .text-box > * {
    color: $color-white;
    margin-bottom: 60px;
  }

}



.section-02-pre-scolaire {
  background: url(../images/bg_parallax_02.jpg) no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .image-right {
    height: 100%;
    background-image: url('../images/programmes_etude_prescolaire_section_02_img.jpg');
    background-position: center;
    background-size: cover;
    position: absolute;
    right: 0;
  }
  .text-box {
    padding-top: 70px;
    & > * {
      margin-bottom: 60px;
      color: $color-white;
    }
    .section-listes{
      @include section-listes;
      // &.left {
      //   padding-top: 80px;
      //   margin-top: 10px;
      //   border-top: 1px solid $color-white;
      //   border-bottom: 1px solid $color-white;
      //   padding-bottom: 50px;
      //   h4 {
      //     padding-bottom: 30px;
      //   }
      // }
    }
  }
  .subtitle {
    font-size: $font-size-30;
  }

  @media screen and (max-width: $size-xs-max){

    .image-right {
      position: relative;
      height: 400px;
      background-position: top;
    }
  }
}

.section-03-programme {
  background: $color-white;
  display: flex;
  padding: 50px 0 0!important;
  .conatiner.row {
    display: flex;
    align-items: center;
    justify-content: space-between;;
  }
  .content-box {
    .section-listes {
      @include section-listes;
    }
    hr {
      border-top: 2px solid $color-green-2;
      margin: 0;
    }
    h4 {
      padding: 30px 0;
    }
    li {
      line-height: 2;
      color: $color-black;
    }
    img {
      margin: auto;
    }
  }
  .title-black {
    font-size: $font-size-30;
    @extend .font-3-bold;
  }
  .subtitle {
    color: $color-green-2;
  }

}


/////////////// page primaire//////////////////////////////////////

.section-02-primaire {
  background-image: url('../images/programme_etude_prescolaire_section_02_bg_parallax.jpg') ;
  background-position: center;
  background-repeat: no-repeat ;
  background-size: cover;
  background-attachment: fixed;
  padding: 0;
  position: relative;
  .image-right {
    height: 100%;
    background-image: url('../images/programmes_etude_primaire_section_02_img.jpg');
    background-position: center;
    background-size: cover;
    position: absolute;
    right: 0;
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  .row {
    margin: auto;
    padding: 0;
    .text-box {
      padding: 100px 0;
      & > div {
        & > * {
          color: $color-white;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media screen and (max-width: $size-xs-max){
    
    .row {
      .text-box {
        ul {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
}

///////////////page secondaire/////////////////

.section-programme-green {
  background: $color-green!important;
  display: flex;
  padding: 50px 0 0!important;
  .conatiner.row {
    display: flex;
    align-items: center;
    justify-content: space-between;;
  }
  .content-box {
    .section-listes {
      @include section-listes;
      h4 {
        padding: 30px 0;
        font-family: $font-family-1;
      }
    }
    h3.title {color: $color-white;}

    li {
      line-height: 2;
      color: $color-white;
    }
    img {
      margin: auto;
    }
  }
  .title-black {
    font-size: $font-size-30;
    @extend .font-3-bold;
  }
  .subtitle {
    color: $color-white;
  }

}



//////services-aux-eleves////////////////

.services-aux-eleves {
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: $color-green-2;
  }
  .link {
    display: flex;
    align-items: center;
    color: $color-green-2;
    font-size: $font-size-30;
    &:hover {
      color: $color-black;
    }
    img {
      margin-left: 20px
    }

  }
  .top-box > * {
    padding: 25px 0
  }
  .content-box {
    display: flex;
    margin: 50px 0;
    .text-box {
      flex: 0 1 70%;
      border-top: 2px solid $color-green-2;
      padding-right: 20px;
      ul {
        padding-left: 20px;
       li {color: $color-black;}
      }
      & > * {
        padding: 25px 0
      }
    }
    .img-box {
      flex: 0 1 30%;
    }
  }
  @media screen and (max-width: $size-xs-max){
    .link {justify-content: center;}
    .content-box {
      .text-box , .img-box{
        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
         li {text-align: center;}
        }
      }
    }
  }
  @media screen and (max-width: $size-max-m-3){
    .content-box {
      flex-direction: column;
      .text-box , .img-box{
        width: 100%;
        img {margin: auto;}
      }
    }
  }

}


/////services-parascolaires/////////////////////////////
.section-services-parascolaires {
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;

  .content-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    transition: .5s;
    & > div {
      flex: 0 1 26%;
      margin: 40px auto;
      transition: .5s;
    }
    h4 {
      color: $color-green-2;
      font-family: $font-family-3;
      font-size: $font-size-30;
      padding-bottom: 20px;
    }
    ul {
      padding-left: 20px;
     li {
       color: $color-black;
       line-height: 1.5;
     }
    }
  }
  @media screen and (max-width: $size-xs-max){
    .content-box {
      flex-direction: column;
      transition: .5s;
      & > div {
        flex: auto;
        transition: .5s;
        &.text-box {
          order: -1;
          transition: .5s;
        }
      }
    }
  }
}

////////////////////////////////////////////////////////
////////uniformes//////////////////////////////////////
.section-uniformes {
  @extend .section-services-parascolaires;
  .content-box {
    padding: 50px 0;
    & > div {
      flex: 0 1 35%;
    }
    div.btn-general {
      background: $color-green-2;
      height: 55px;
      width: auto;
      //max-width: 300px;
    }
    div.btn-general:hover {
        background: $color-maron-hover;
        transition: .5s;
    }
  }
  @media screen and (max-width: $size-xs-max){
    .content-box {
      padding: 20px 0;
      ul {
        transition: .5s;
        display: flex;
        padding-left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       li {
         text-align: center;
         transition: .5s;
       }
      }
    }
  }
}


///////////////////////////////////////////////////////
///transport
.section-transport {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../images/vie_a_lecole_transport_section_01_bg_parallax.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  div.content-box {
    background: $color-white;
    padding: 20px;
    margin: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .text-box {
      border: 2px solid $color-green-2;
      position: relative;
      width: 100%;
      img {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      p {
        color: $color-green-2;
        line-height: 1.5;
        text-align: center;
        font-size: $font-size-24;
        padding: 40px;
        margin: 0;
      }

    }
  }
}


////////////////////////////////////////////////////////
////section-cafeteria
.section-cafeteria {
  display: flex;
  align-items: center;
  padding-bottom: 120px;
  background-size: auto;
  justify-content: center;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-image: url('../images/vie_a_lecole_cafetaria_menus_section_01_bg.jpg');
  .row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .content-box {
    margin-bottom: 120px;
    flex: 0 1 60%;
    p {
      color: $color-green-2;
    }

  }
  @media screen and (max-width: $size-xs-max){
    .content-box {
      flex: auto;
      padding: 10px;
      width: 100%;
    }
  }
}

////////////////////////////////////////////////////////
//section-admission-inscriptions
.section-admission-inscriptions {
  background-position: right;
  background-image: url('../images/procedures_section_01_bg.jpg');
  background-repeat: no-repeat;
  padding-bottom: 60px!important;

  background-size: 50% auto;

  .row {margin: auto;}
  .content-box {
    & > * {
      color: $color-green-2;
      padding: 15px 0;
    }
    .title-box {
      display: flex;
      margin-bottom: 50px;
      justify-content: flex-start;
      .title {
        border: 2px solid $color-green-2;
        padding: 15px 25px;
        color: $color-green-2;
      }
    }

    ul, li {
      padding-left: 20px;
    }
    h4 {
      font-size: $font-size-30;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  @media screen and (max-width: $size-max-m-2) {
    background-image: none;
    .content-box {
      .title-box {
        justify-content: center;
      }
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
        li {
          padding: 0;
          white-space: nowrap;
        }
      }
    }
  }
  @media screen and (min-width: $size-max-2) {
    background-size: auto;
  }
}


//////////////////////////////////////////////////////
////section-admission-procedures
.section-admission-procedures {
  @extend .section-admission-inscriptions;
  .content-box {
    ul {
      li {
        color: $color-black;
        white-space: normal;
      }
    }
    h4 span {
      font-size: $font-size-18;
    }
  }
  @media screen and (max-width: $size-max-m-2) {
    .content-box {
      ul {
        li {
          text-align: center;
        }
      }
    }
  }
}

/////////////////////////////////////////////////////////
//////section-admission-portes-ouvertes
.section-admission-portes-ouvertes {

    .row {margin: auto;}
    .content-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 60px;
    }
    .title-box {
      border: 2px solid $color-green-2;
      padding: 25px 50px;
      margin-bottom: 50px;
      max-width: 670px;
      text-align: center;
      .title {
        color: $color-green-2;
        padding-bottom: 25px;
        font-weight: 400;
      }
      p {
         color: $color-black;
      }
    }
    .video-box {
        padding-bottom: 100px;
        .title {
        color: $color-green-2;
        padding-bottom: 35px;
        font-weight: 400;
        text-align: center;
      }
      .videos {
        display: flex;
        .video.first {
            padding-right: 50px;
        }
        p {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 2;
            color: #000;
            text-align: center;
        }
      }
    }
    .galerie {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;
      & > div {
        flex: 0 1 33%;
        padding: 25px;
      }
    }
    @media screen and (max-width: $size-sm-max) {
      .galerie {
        & > div {
          flex: 0 1 50%;
          padding: 25px;
        }
      }
    }
    @media screen and (max-width: $size-max-m-4) {
      .galerie {
        & > div {
          flex: 0 1 100%;
          padding: 15px;
        }
      }
    }
}



////////////////////////////////////////////////////////
////////section-temoignage
.section-temoignage {
  .row {margin: auto;}
  padding-bottom: 50px!important;
  .content-box {
    border: 2px solid $color-green-2;
    padding: 0;
    margin: 50px 0;
    .top-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 20px;
      width: fit-content;

      border-bottom: 2px solid $color-green-2;
      h3 {
        color: $color-green-2;
        font-size: $font-size-26;
      }
      p {
        margin: 0;
        font-weight: 700;
        padding-left: 10px;
        color: $color-black;
        font-size: $font-size-22;
      }
    }
    .desc {
      padding: 15px;
      font-size: $font-size-20;
      color: $color-black;
    }
  }
  @media screen and (max-width: $size-xs-max){
    .content-box {
      .top-box {
        margin: auto;
        justify-content: center;
      }
    }
  }
}


////////////////////////////////////////////////////////
////page coordonnees
.section-coordonnees-01 {
  background-image: url('../images/coordonnees_section_01_bg_parallax.jpg');
  background-position: center;
  background-size: auto 120%;
  background-attachment: fixed;
  padding: 100px 0;
  .row {margin: auto;}
  h4 {
    color: $color-maron-2;
    line-height: 1.5;
    text-align: center;
    font-size: $font-size-30;
    text-shadow: 0 0 10px $color-black;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &, & > * {
      color: $color-white;
      font-size: $font-size-30;
      line-height: 1.5;
      padding: 0 10px;
      white-space: nowrap;
      text-shadow: 0 0 10px $color-black;
    }
    & > a:hover {
      color: $color-maron-2;
    }

  }
}


.section-coordonnees-02 {
  padding: 100px 0 30px;
  background: $color-white;
  .row {margin: auto;}
  .adresse-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: .5s;
    & > div.left  {
      margin: 20px 70px 0 0;
      transition: .5s;
    }
    & > div.right  {
      margin: 20px 0 0 70px;
      transition: .5s;
    }
  }
  h4 {
    font-size: $font-size-36;
    color: $color-green-2;
    line-height: 1.5;
    font-weight: 500;
  }
  p {
    color: $color-black;
    font-size: $font-size-26;
  }
  .left {text-align: right;}
  @media screen and (max-width: $size-sm-max){
    .adresse-section {
      & > div.left, & > div.right  {
        margin: 20px 15px;
        transition: .5s;
      }
    }
  }
  @media screen and (max-width: $size-xs-max){
    .adresse-section {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      transition: .5s;
    }
  }
}

.section-coordonnees-03 {
  padding: 30px 0 100px 0;
  background: $color-white;
  .row {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .map {
    border: 2px solid $color-green-2;
    padding: 0;
    margin: 10px;
  }
  @media screen and (max-width: $size-xs-max){
    .row {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}

////////////////////////////////////////////////////////
.section-after-burst {
  padding: 125px 0 0;
  @media screen and (max-width: $size-max-m-3){
    .row { margin-top: 185px; }
  }
}



////////////////////////////////////////////////////////

//buttom style
div.btn-general {
    width: 75%;
    height: 45px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: $color-maron-3;
    padding: 5px;
    transition: .5s;
    max-width: 350px;

    a.btn-link {
        width: 100%;
        height: 100%;
        font-weight: 500;
        border: 1px solid $color-white;
        text-transform: uppercase;
        font-size: 16px;
        color: $color-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

div.btn-general:hover {
    background: $color-maron-hover;
    transition: .5s;
}


h3.empty-section {
    height: 75px;
    color: #ccc;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    text-transform: none;
    width: 100%;
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content:"";
  display:block;
  height:$size-nav-height; /* fixed header height*/
  margin:-$size-nav-height 0 0; /* negative fixed header height */
}
.shadow {
	position: relative;
	&::after {
		content: '';
		z-index: 1;
		width: 100%;
		top: 100%;
		height: 20px;
		position: absolute;
		background: linear-gradient(to bottom , rgba(0 ,0 ,0 ,.6), rgba(0 ,0 ,0 ,0) );
	}
}

.shadow-section-3 {
  position: relative;
	&::after {
		content: '';
		z-index: 1;
		width: 100%;
		top: 100%;
		height: 40px;
		position: absolute;
    background: url(../images/section_03_tab_shadow.png) no-repeat;
	}
}



/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
 //@import 'sections/section-header-page';
//
 //@import 'sections/section-text-image';
//
 @import 'sections/section-bursts';
//
 @import 'sections/_section06-popup';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-header';

/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/
div.module-loi25 {
    position: fixed;
    bottom: 10px;
    max-width: 320px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 9;
    div.content-loi25 {
        //box-shadow: 0 4px 5px 2px rgba(171,171,171,.45);
        margin-bottom: 20px;
        visibility: hidden;
        opacity: 0;
        height: 50%;
        transition: .2s;
        display: none;
        &.toggled {
            visibility: visible;
            opacity: 1;
            height: 100%;
            display: block;
            transition: .2s;
        }
        div.header {
            font-size: 30px;
            text-align: center;
            font-weight: 400;
            text-transform: uppercase;
            color: #fff;
            padding: 20px 20px;
            background:#151c54;// $color-1;
            border-top-right-radius:30px;
            border-top-left-radius:30px;
            box-shadow: 0 4px 5px 2px rgba(171,171,171,.45);
        }
        div.description {
            margin-top: 0;
            margin-bottom: 0;
            padding: 50px 20px;
            background-color: rgb(255, 255, 255);
            border-bottom-right-radius:30px;
            border-bottom-left-radius:30px;
            box-shadow: 0 4px 5px 2px rgba(171,171,171,.45);
            h4 {
                color: $color-black;
                font-weight: 400;
                font-size: 22px;
                font-family: $font-family-3;
                text-align: center;
                line-height: 30px;
                margin-bottom: 25px;
                transition: .5s;
            }
            p {
    
                color: $color-black;
                font-weight: 400;
                font-size: 17px;
                font-family: $font-family-3;
                text-align: center;
                line-height: 30px;
                margin-bottom: 25px;
                transition: .5s;
            }
        }
    }
    div.content-logo-loi25 {
        cursor: pointer;
    }
}

/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {
        transition: all .2s linear;
        &:hover {
            transition: all .2s linear;
        }
    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: 1024px){

iframe {
    width: 400px;
    height: 250px;
}
}

@media screen and (max-width: $size-sm-max){
 
.section-admission-portes-ouvertes .video-box .videos {
    flex-direction: column;
}
iframe {
    width: 560px; 
    height: 315px;
}
.section-admission-portes-ouvertes .video-box .videos .video.first {
    padding-right: 0;
    padding-bottom: 50px;
}
}

@media screen and (max-width: $size-xs-max){

.section-admission-portes-ouvertes .video-box .videos .video.first {
    padding-right: 0;
    padding-bottom: 30px;
}
iframe {
    width: 360px;
    height: 230px;
}
}
