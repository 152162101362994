/* @include transform(normal_css_value_here) */
@mixin transform($value) {
	-webkit-transform: $value;
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
	transform: $value;
}

/* @include transition(normal_css_value_here) */
@mixin transition($value) {
	-webkit-transition: $value;
	-moz-transition: $value;
	-ms-transition: $value;
	-o-transition: $value;
	transition: $value;
}

/* @include box-shadow(normal_css_value_here) */
@mixin box-shadow($value) {
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	-ms-box-shadow: $value;
	-o-box-shadow: $value;
	box-shadow: $value;
}

/* @include text-shadow(normal_css_value_here) */
@mixin text-shadow($value) {
	-webkit-text-shadow: $value;
	-moz-text-shadow: $value;
	-ms-text-shadow: $value;
	-o-text-shadow: $value;
	text-shadow: $value;
}

/* @include no-select() */
@mixin no-select() {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

/* @include text-style() */
@mixin text-style ($size, $color, $family, $weight) {
	color: $color;
	font-size: $size;
	font-family: $family;
	font-weight: $weight;
}

/***************************************************/
/* Select mixins
/* usage example:
li {
  @include first(4)
  {
    background: red;
  }
}
*/

/* @include to select child elements */
@mixin first-child() {
	&:first-of-type {
		@content
	}
}
/* Selects the last element */
@mixin last-child() {
	&:last-of-type {
		@content
	}
}
/* Selects the even elements */
@mixin even(){
	&:nth-child(even) {
		@content
	}
}
/* Selects the odd elements */
@mixin odd(){
	&:nth-child(odd) {
		@content
	}
}
/* Selects the n first elements */
@mixin first($num) {
	@if $num == 1 {
		&:first-child {
			@content;
		}
	}
	@else {
		&:nth-child(-n + #{$num}) {
			@content;
		}
	}
}
@mixin last($num) {
	&:nth-last-child(-n + #{$num}) {
		@content;
	}
}
@mixin after($num) {
	&:nth-child(n+#{$num + 1}) {
		@content
	}
}
@mixin from-end($num) {
	&:nth-last-child(#{$num}) {
		@content
	}
}
@mixin between($first,$last) {
	&:nth-child(n+#{$first}):nth-child(-n+#{$last}) {
		@content
	}
}
@mixin all-but($num) {
	&:not(:nth-child(#{$num})) {
		@content
	}
}
@mixin each($num) {
	&:nth-child(#{$num}n) {
		@content
	}
}
/***************************************************/
